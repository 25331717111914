<template>
  <div class="counter d-inline-flex align-items-center">
    <div class="op-btn" @click="decrement();">
      <img src="../../assets/svgs/shopping_list_ic_subtract_dis.svg">
    </div>
    <input type="text" class="count text-center" v-model="value">
    <div class="op-btn" @click="increment();">
      <img src="../../assets/svgs/shopping_list_ic_add_sel.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCounter',

  data: function () {
    return {
      value: this.count
    }
  },

  props: {
    count: {
      type: Number,
      default: 1
    }
  },

  emits: ['update'],

  watch: {
    value (newVal) {
      this.$emit('update', parseInt(newVal))
    }
  },

  methods: {
    decrement: function () {
      if (isNaN(this.value)) {
        this.value = 1
      }
      this.value = parseInt(this.value)
      if (this.value <= 1) {
        return
      }
      this.value -= 1
    },

    increment: function () {
      if (isNaN(this.value)) {
        this.value = 1
      }
      this.value = parseInt(this.value)
      this.value += 1
    }
  }
}
</script>

<style lang="scss" scoped>
.counter {
  height: 100%;
  line-height: 100%;
  min-width: 2.2rem;

  .op-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.2rem;
    width: 2.0rem;
    height: 100%;

    :hover {
      cursor: pointer;
    }

    img {
      height: 100%;
    }
  }

  .count {
    height: 100%;
    width: 2.5rem;
    //font-size: 0.8rem;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-user-select: none;
  }
}
</style>
