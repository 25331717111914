export default function getSimpleSpecString (specs) {
  const specList = specs.split(';')
  const specValues = []
  specList.forEach(item => {
    const values = item.split(':')
    if (values && values.length > 1) {
      specValues.push(values[1])
    }
  })
  return specValues.join(' / ')
}
