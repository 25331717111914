import request from '@/utils/request'

const orderApi = {
  CreateNewOrder: '/b/order/new_order',
  Pay: '/b/order/pay',
  List: '/b/order/list',
  Expire: '/b/order/payment_expire',

  ShippingInfo: '/b/order/shipping_info'
}

export function getShippingInfo (params) {
  return request({
    url: orderApi.ShippingInfo,
    method: 'post',
    data: params
  })
}

export function setOrderPaymentExpire (params) {
  return request({
    url: orderApi.Expire,
    method: 'post',
    data: params
  })
}

export function getOrderList (params) {
  return request({
    url: orderApi.List,
    method: 'post',
    data: params
  })
}

export function createNewOrder (params) {
  return request({
    url: orderApi.CreateNewOrder,
    method: 'post',
    data: params
  })
}

export function pay (params) {
  return request({
    url: orderApi.Pay,
    method: 'post',
    data: params
  })
}
