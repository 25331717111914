<template>

<!--  <div class="container-fluid no-scroll">-->
<!--    <div class="row">-->
<!--      <div class="col-12 px-0">-->
<!--        <img src="../../assets/svgs/shopping_banner.svg" style="height: 100px;">-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="container-fluid no-scroll my-1">
    <div class="container">
      <div class="row">
        <div class="px-0 col-12 product d-flex align-items-center mt-1 py-2" v-for="(cartItem, index) in cartItems"
             v-bind:key="cartItem.id">
          <div class="checkBox">
            <input type="checkbox" class="form-check-input" v-model="cartItem.checked">
          </div>
          <div class="info d-flex flex-column justify-content-around ml-1">
            <div class="model fw-bold">
              <span class="badge" style="background: #0B1423">{{ cartItem.grade }}</span> {{ cartItem.name }}
            </div>
            <div class="spec">{{ getSimpleSpecString(cartItem.specs) }}</div>

            <div class="d-flex align-items-center justify-content-between">
              <div class="text-danger">
                <span v-if="cartItem.request_price && cartItem.status === BargainStatusCode.Approved">
                  <span class="h5">{{ toThousands(cartItem.request_price) }}</span>
                  <span class="postfix"> AED</span>
                  <span class="text-decoration-line-through" style="font-size: 0.6rem; margin-left: 1rem;">{{ toThousands(cartItem.price) }}</span>
                  <span class="text-decoration-line-through postfix"> AED</span>
                </span>
                <span v-else>
                  <span class="h5">{{ toThousands(cartItem.price) }}</span>
                  <span class="postfix"> AED</span>
                </span>
              </div>
              <div class="counter">
                <ProductCounter :count="cartItem.qty"
                                @update="val => {updateCartItemQty(cartItem, val)}"></ProductCounter>
              </div>
            </div>
            <BargainPrice
                v-if="!cartItem.request_price"
                v-model:cid="cartItem.id"
                v-model:sid="cartItem.seller_id"
                v-model:bid="user.id"
                v-model:stock="cartItem.stock_id"
                v-model:currency="cartItem.currency"
                v-model:name="cartItem.name"
                v-model:originalPrice="cartItem.price"
                @onBargainSubmitted="loadCartItems()"
            />
            <div v-else-if="cartItem.status === BargainStatusCode.Requesting">
              <span class="requested">
                Requested for {{ cartItem.request_price }} {{ cartItem.currency }}, waiting for reply.
              </span>
            </div>
            <div v-else-if="cartItem.status === BargainStatusCode.Rejected">
              <span class="requested">
                Request for {{ cartItem.request_price }} {{ cartItem.currency }} has been rejected.
              </span>
            </div>
            <div class="small d-flex justify-content-between align-items-center">
              <div>
                  <span class="d-flex align-items-center justify-content-start">
                    <fa icon="map-marker-alt"></fa>
                    <span class="small" style="margin-left: 0.5rem;">{{ cartItem.country }}</span>
                  </span>
              </div>
              <div style="padding-right: 0.5rem;">
                <fa icon="trash" style="font-size: 0.8rem;color: gray" data-bs-toggle="modal"
                    data-bs-target="#removeModal" @click="itemToBeRemoved=cartItem;removeItemIndex=index"></fa>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-if="cartItems.length > 0">
        <div class="col-12 px-5 d-flex align-items-center justify-content-between">
          <div style="height: 2rem; width: 6rem;" class="d-flex align-items-center">
            <input class="selectAllCb" type="checkbox" v-model="selectAll" style="height: 100%; width: 1rem;">
            <span class="selectAll">Select All</span>
          </div>
          <div style="text-align: right">
            <span style="font-size: 0.8rem;font-weight: bold">Sum: </span><span class="sumMoney">{{
              toThousands(total)
            }}</span>
            <!-- @todo 这里采用偷懒的做法，采用了第一个购物车项的货币，万一货币不统一怎么办？-->
            <span class="postfix" style="margin-left: 0.3rem;"> {{ cartItems[0]?.currency }}</span>
            <div style="font-size: 0.6rem; color: gray">Delivery cost are not included</div>
          </div>
        </div>
        <div class="col-12 px-5">
          <button class="my-3 btn w-100 fw-bold checkout bg-trans" @click="checkOut" :disabled="total<=0">Submit Order</button>
        </div>
      </div>
      <div v-else style="height: 10rem; display: flex; justify-content: center; align-items: center">
        No item in cart,&nbsp;
        <router-link to="/">exploring products now...</router-link>
      </div>
    </div>
  </div>
  <div class="modal fade" id="removeModal" tabindex="-1" style="margin: 0 auto;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          Are you sure to remove {{ itemToBeRemoved ? itemToBeRemoved.name : '' }}?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  @click="removeItem(itemToBeRemoved)">Remove
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { delFromCart, listCart, updateCartQty } from '../../api/user'
import { createNewOrder } from '../../api/order'
import ProductCounter from '../../components/product/ProductCounter'
import getSimpleSpecString from '../../api/specs'
import BargainPrice from '../../components/product/BargainPrice'
import { BargainStatusCode } from '../../api/codes'

export default {
  name: 'Cart',
  components: { ProductCounter, BargainPrice },
  created () {
    this.loadCartItems()
  },

  emits: ['onBargainSubmitted'],

  data () {
    return {
      cartItems: [],
      hasMoreItems: false,
      selectAll: false,
      itemToBeRemoved: undefined,
      removeItemIndex: -1,
      BargainStatusCode
    }
  },

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)
    const checkOutCart = ({ items }) => store.dispatch('cart/checkOutCart', { items })

    const cartCount = computed(() => store.state.cart.cartCount)
    const updateCartCount = (count) => store.dispatch('cart/updateCartCount', count)

    return {
      user,
      checkOutCart,
      cartCount,
      updateCartCount
    }
  },

  computed: {
    total () {
      let sum = 0
      this.cartItems.map((item) => {
        if (item.checked) {
          if (item.request_price && item.request_price > 0 && item.status === 1) {
            sum += item.qty * item.request_price
          } else {
            sum += item.qty * item.price
          }
        }
      })
      return sum
    }
  },

  watch: {
    selectAll: {
      handler (newValue) {
        if (newValue) {
          this.cartItems.map((item) => {
            item.checked = true
          })
        } else {
          this.cartItems.map((item) => {
            item.checked = false
          })
        }
      }
    }
  },

  methods: {
    checkOut () {
      const checkOutItems = []
      this.cartItems.forEach((item) => {
        if (item.checked) {
          checkOutItems.push({
            name: item.name,
            specs: item.specs,
            grade: item.grade,
            country: item.country,
            currency: item.currency,
            cart_id: item.id,
            price: (item.request_price && item.status === BargainStatusCode.Approved) ? item.request_price : item.price,
            qty: item.qty,
            stock_id: item.stock_id,
            seller_id: item.seller_id
          })
        }
      })

      const params = {
        buyer_id: this.user.id,
        items: checkOutItems
      }

      this.$loading.show('Submitting order information...')
      createNewOrder(params).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.updateCartCount(this.cartCount - checkOutItems.length)
          this.$router.push({ path: '/order' })
        } else {
          this.$toast.show('Failed to submit, please try again')
          console.log(res.code)
        }
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    removeItem (item) {
      delFromCart({ id: item.id }).then(res => {
        if (res.code === 0) {
          this.cartItems.splice(this.removeItemIndex, 1)
          this.removeItemIndex = -1
          this.itemToBeRemoved = undefined
          this.updateCartCount(this.cartCount - 1)
        } else {
          console.log('Failed to remove cart item')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    loadCartItems () {
      this.$loading.show('Loading cart items...')
      listCart({ buyer_id: this.user.id }).then(res => {
        if (res.code === 0) {
          this.cartItems = res.data.cartItems
        } else {
          this.$toast.show('Oops... something wrong, please try again.')
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    updateCartItemQty (item, qty) {
      item.qty = qty
      updateCartQty({
        id: item.id,
        quantity: qty
      }).then(res => {
        if (res.code === 0) {
          console.log('ok')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    getSimpleSpecString
  }
}
</script>

<style scoped lang="scss">

.spec {
  color: #6D737C;
}

.sumMoney {
  color: red;
  font-size: 1.5rem;
}

.postfix {
  font-size: 0.6rem;
  color: red;
}

.counter {
  padding-right: 0.5rem;
  height: 1.5rem;
}

.selectAll {
  margin-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.2rem;
}

.checkBox {
  margin: 0 2rem;

  input {
    box-shadow: none;
    border: 1px solid #ccc;
    &:checked {
      border: none;
      background-image: url("~@/assets/icons/com_list_cb_sel@3x.png");
    }
  }
}

.checkout {
  //background: linear-gradient(270deg, #F1651A 0%, #DE350B 100%);
  border-radius: 10px;
  color: white;
}

.requested {
  font-size: 0.6rem;
  padding: 0.1rem 0.3rem;
  background: #f1f1f1;
}

</style>
