export const LoginCode = {
  SUCCEED: 0,
  USER_NON_EXIST: 1,
  WRONG_PASSWORD: 2,
  USER_INACTIVE: 3,
  USER_LOCKED: 4
}

export const RegisterResultCode = {
  SUCCEED: 0,
  USER_EXIST: 1,
  NEED_EMAIL_VERIFICATION: 2,
  VERIFICATION_CODE_EXPIRE: 3,
  VERIFICATION_CODE_NON_EXIST: 4,
  WRONG_VERIFICATION_CODE: 5
}

export const PickupCode = {
  SELF_PICKUP: 1,
  AGENT_PICKUP: 2,
  SEND_TO_AGENT: 3,
  NORMAL_SHIPPING: 4
}

export const PayResultCode = {
  INSUFFICIENT_BALANCE: 1,
  ORDER_CANCEL: 2,
  ORDER_HAS_BEEN_PAID: 3
}

export const OrderStatusCode = {
  All: -1,
  Progressing: 0,
  Finished: 1,
  Cancel: 2
}

export const BargainStatusCode = {
  Requesting: 0,
  Approved: 1,
  Rejected: 2,
  Timeout: 3
}
