<template>
  <div class="bargain">
    <img v-if="show" @click="showPrice" src="../../assets/svgs/bargain-pre.svg">
    <img v-else @click="show=!show" src="../../assets/svgs/bargain.svg">
    <input ref="price" placeholder="Price you want" :class="{'active': show}" type="number" class="form-control" v-model="price">
    <span ref="text" @click="setBargainPrice" :class="{'active': show}">Bargain</span>
  </div>
</template>

<script>

import { updateBargainPrice } from '../../api/user'

export default {
  name: 'BargainPrice',

  props: {
    cid: Number,
    bid: Number,
    sid: Number,
    stock: Number,
    name: String,
    currency: String,
    originalPrice: Number
  },

  data () {
    return {
      show: false,
      price: undefined
    }
  },

  watch: {
    show (val) {
      if (val) {
        this.$refs.text.innerText = 'Submit'
      } else {
        this.$refs.text.innerText = 'Bargain'
      }
    }
  },

  methods: {
    showPrice () {
      this.show = !this.show
      if (this.show) {
        this.$refs.price.focus()
      }
    },
    setBargainPrice () {
      if (!this.show) {
        this.show = true
      } else {
        if (typeof this.price === 'undefined' || this.price <= 0) {
          this.$toast.error('Please input price you can take')
          return
        }

        if (this.price > this.originalPrice) {
          this.$toast.error('Price even higher, are you sure?')
        }

        const params = {
          cart_id: this.cid,
          buyer_id: this.bid,
          seller_id: this.sid,
          stock_id: this.stock,
          currency: this.currency,
          name: this.name,
          original_price: this.originalPrice,
          price: this.price
        }

        this.$loading.show('Submitting your request price...')

        updateBargainPrice(params).then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.show = !this.show
            this.$toast.success('Successfully submitted request for new price, please waiting for reply')
            this.$emit('onBargainSubmitted')
          } else {
            this.$toast.error('Failed to submit request for new price, please retry')
          }
        }).catch(err => {
          this.$loading.hide()
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.bargain {
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  color: white;
  font-size: 0.8rem;
  margin: 0.3rem 0;

  input {
    font-size: 1rem;
    height: 1.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0;
    max-width: 0;
    border: 0px solid #de350b;
    transition: 0.2s;

    &.active {
      margin-left: 0.5rem;
      max-width: 150px;
      border: 1px solid #de350b;
      transition: 0.2s;
    }
  }

  span {
    cursor: pointer;
    position: relative;
    color: #de350b;
    height: 1.8rem;
    line-height: 1.8rem;
    left: -0.7rem;

    &.active {
      padding: 0 0.4rem;
      font-weight: bold;
      background: #de350b;
      color: white;
    }
  }

  img {
    width: 1.3rem;
    cursor: pointer;
  }
}

</style>
